/* Define Local Variables */

:root {
  --tooltip-var: #ff6961;
}

.alert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  right: 20px;
  top: 0px;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.5em !important;
  z-index: 2000000;
  animation: appear 0.5s ease-in-out forwards;
}

.alert-body {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.alert p {
  margin: 0;
}
.alert i {
  font-size: 1em;
  cursor: pointer;
  margin-right: 10px;
}

.alert-error {
  color: var(--white);
  background-color: var(--danger);
  margin-top: 10px;
}

.alert-success {
  color: var(--bg-primary);
  background-color: var(--success);
  margin-top: 10px;
}

.alert-warning {
  color: var(--bg-primary);
  background-color: var(--warning);
  margin-top: 10px;
}

.alert-info {
  color: var(--bg-primary);
  background-color: var(--info);
  margin-top: 10px;
}

.alert .click-me {
  cursor: pointer;
  position: absolute;
  font-size: 0.5em;
  right: 0px;
  top: 0px;
}

.alert.clickable {
  cursor: pointer;
}

.alert.clickable:hover {
  scale: 1.02;
}

@media screen and (max-width: 768px) {
  .alert {
    right: 10px;
    font-size: 1em !important;
  }
}
