.tooltip-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

.tooltip {
  position: absolute;
  background-color: var(--black);
  color: var(--white);
  padding: 8px 12px;
  border-radius: 3px;
  font-size: 14px;
  white-space: nowrap;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0.2s ease;
}

.tooltip-container:hover .tooltip {
  opacity: 1;
  visibility: visible;
}

/* Position classes */
.tooltip-top {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 8px;
}

.tooltip-bottom {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 8px;
}

.tooltip-left {
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  margin-right: 8px;
}

.tooltip-right {
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  margin-left: 8px;
}

/* .tooltip-container .tooltip:after {
  position: relative;
  border-left: solid transparent 5px !important;
  border-right: solid transparent 5px !important;
  border-top: solid var(--black) 5px;
  bottom: -5px;
  content: " ";
  margin: 0 auto;
  position: absolute;
} */
